/* VARIABLES
---------------------------------------------- */
:root {
  /* Fonts */
  --serif-font: 'Times New Roman', serif;
  --sans-serif-font: 'Inter', sans-serif;

  /* Font sizes */
  --font-size: 16px;
  --x-small-font-size: 0.875rem;
  --small-font-size: 1.0625rem;
  --medium-font-size: 1.125rem;
  --medium-large-font-size: 1.25rem;
  --large-font-size: 5.1vw; // header
  --xx-large-font-size: 1.5rem;

  /* Line height */
  --standard-line-height: 105%;

  /* Vertical margins */
  --xx-small-vertical-margin: .6rem;
  --x-small-vertical-margin: 1rem;
  --small-vertical-margin: 2rem;
  --medium-vertical-margin: 3rem;
  // --large-vertical-margin: 9rem;
  // --x-large-vertical-margin: 12rem;
  --xx-large-vertical-margin: 6.625rem;

  /* Colors */
  --black: #000;
  --white: white;
  --grey: #D7D7D7;

  /* Widths, paddings, gaps */
  /* --vh: 1; */
  // --small-width: 500px;
  // --medium-width: 650px;
  // --large-width: 750px;
  // --x-large-width: 800px;
  // --xx-large-width: 990px;
  // --page-width: 1300px;
  --lr-page-padding: 9px;
  // --medium-grid-gap: clamp(1.8rem, 4.5vw, 4.5rem);
  // --large-grid-gap: calc(var(--lr-page-padding)*3);

  /* Set up */
  font-family: var(--sans-serif-font);
  font-size: var(--font-size);
  color: var(--black);
}

/* DESKTOP CSS VARIABLES
---------------------------------------------- */
@media (min-width: 850px) {
  :root {
    --large-font-size: 1.375rem; 
  }
}