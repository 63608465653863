@mixin underlined-link {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  @include for-above-small-tablet {
    text-decoration-thickness: .06em;
    transition: text-decoration-color .4s ease;
    text-underline-position: under;
    &:hover{
      text-decoration-color: transparent;
    }
  }
}