@import "fonts";
@import "reset";
@import "media-queries";
@import "variables";
@import "form-inputs";
@import "mixins";
@import "../node_modules/@splidejs/splide/dist/css/splide.min";

@media (hover: hover) {
  .button:hover {
  }
}

/* GLOBAL
---------------------------------------------- */
body {
  font-family: var(--body-font);
  color: var(--black);
  -webkit-font-smoothing: antialiased;
}
html {
  scroll-behavior: smooth;
  scroll-padding-top: 100px;
  @include for-above-small-tablet {
    scroll-padding-top: 44px;
  }
}
main {
  padding: 0 var(--lr-page-padding);
}
.visually-hidden {
  position:absolute !important;
  overflow:hidden;
  width:1px;
  height:1px;
  margin:-1px;
  padding:0;
  border:0;
  clip:rect(0 0 0 0);
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap:normal !important
}
.center {
  display: flex;
  justify-content: center!important;
  align-items: center!important;
}
img {
  width: 100%;
  height: auto;
  display: block;
}
.text-align-center {
  text-align: center;
}
.uppercase {
  text-transform: uppercase;
}
.italic {
  font-style: italic;
}
.underlined a, .underlined, .button a {
  // text-decoration-color: var(--black);
  @include underlined-link;
}
.dark-background {
  .underlined,
  .button a {
    text-decoration-color: var(--white);
  }
  .underlined-on-hover a,
  a.underlined-on-hover {
    &:hover,
    &.active-link {
      text-decoration-color: var(--white);
    }
  }
}
p a {
  color: inherit;
}
.lr-page-padding {
  padding-left: var(--lr-page-padding);
  padding-right: var(--lr-page-padding);
}
.x-small-margin-bottom {
  margin-bottom: var(--small-vertical-margin);
}
.small-margin-bottom {
  margin-bottom: var(--small-vertical-margin);
}
.medium-margin-bottom {
  margin-bottom: var(--medium-vertical-margin);
}
.large-margin-bottom {
  margin-bottom: var(--large-vertical-margin);
}
.x-large-margin-bottom {
  margin-bottom: var(--x-large-vertical-margin);
}
.xx-large-margin-bottom {
  margin-bottom: var(--xx-large-vertical-margin);
}
a {
  color: inherit;
}
/* Lazy loading
---------------------------------------------- */

[data-lazy] {
  visibility: hidden;
}

.image-wrap {
  background-size: cover;
  min-width: 0;
}

/* navbar
---------------------------------------------- */
.navbar {
  background-color: var(--white);
  position: fixed;
  top: -43px;
  width: 100%;
  display: block;
  transition: top 0.3s;
  z-index: 3;
  @include for-above-small-tablet {
    width: initial;
    top: initial!important;
    transform-origin: bottom left;
    left:0;
    bottom:15px;
    transform: rotate(90deg) translateX(-100%);
  }
  &__menu {
    display: flex;
    font-family: var(--serif-font);
    font-size: var(--medium-font-size);
    justify-content: space-between;
    padding: 0 var(--lr-page-padding);
    @include below-small-phone {
      font-size: var(--small-font-size);
    }
  }
  &__link {
    padding-top: var(--lr-page-padding);
    padding-bottom: var(--lr-page-padding);
    display: block;
    text-decoration: none;
    @include for-above-small-tablet {
      padding: var(--lr-page-padding) calc(var(--lr-page-padding)/2);
    }
  }
}
.language-switcher {
  text-transform: uppercase;
}

/* header
---------------------------------------------- */
.header {
  margin-top: var(--xx-small-vertical-margin);
  @include for-above-small-tablet {
    display: flex;
    justify-content: space-between;
  }
}
.title {
  font-size: var(--large-font-size);
  text-transform: uppercase;
  font-weight: 600;
}

/* main
---------------------------------------------- */
@include for-above-small-tablet {
  main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--lr-page-padding);
  }
  .aside {
    align-self: start;
    position: sticky;
    top: 0rem;
    width: calc(100vw - 400px);
  }
  .bside {
    right: 0;
    width: 382px;
    float: right;
    padding-right: var(--lr-page-padding);
  }
}
/* entries
---------------------------------------------- */
.entries {
  margin-top: var(--xx-small-vertical-margin);
  border-top: 1px solid var(--black);
  border-bottom: 1px solid var(--black);
  padding-bottom: var(--lr-page-padding);
  height: 79vh;
  height: 79svh;
  @include for-above-small-tablet {
    // position: relative;
    height: calc(100vh - 25.95px - var(--lr-page-padding) - var(--xx-small-vertical-margin));
    border-right: 1px solid var(--black);
    border-bottom: none;
  }
  &__carousel {
    height: calc(100% - 66px);
    width: 100%;
    @include for-above-small-tablet {
      height: 100%;
    }
  }
  &__carousel-header {
    height: 66px;
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    padding: var(--lr-page-padding) 0;
    font-family: var(--serif-font);
    font-size: var(--medium-large-font-size);
    @include for-above-small-tablet {
      position: absolute;
      width: 100%;
      padding-right: var(--lr-page-padding);
    }
  }
  &__carousel-cell {
    width: 100%;
    align-self: flex-end;
    @include for-above-small-tablet {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__carousel-cell-caption {
    display: none;
  }
  &__image-container {
    display: grid;
  }
  img {
    vertical-align: bottom;
  }
  &__placeholder,
  &__image {
    display: block;
    width: 100%;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    // padding: var(--lr-page-padding) var(--lr-page-padding) 0 var(--lr-page-padding);
    grid-column: 1;
    grid-row: 1;
    object-fit: cover;
    @include for-above-small-tablet {
      height: 400px;
      width: auto;
    }
  }
  &__embed-container,
  &__embed-video-container {
    @include for-above-small-tablet {
      width: 60%;
      height: auto;
      object-fit: cover;
    }
    iframe {
      width: 100%;
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height:100%
    }
  }
  &__embed-video-wrapper {
    padding:56.67% 0 0 0;
    position:relative;
  }
  &__text-container {
    font-family: var(--serif-font);
    font-size: var(--medium-large-font-size);
    @include for-above-small-tablet {
      width: 60%;
    }
    p:not(:last-of-type) {
      margin-bottom: 1.2rem;
    }
  }
}
.splide__track {
  height: 100%;
}
// .splide {
//   visibility: inherit;
//   &.is-initialized, &.is-rendered {
//     visibility: inherit;
//   }
//   &__slide {
//     align-self: start;
//   }
//   &__track {
//     height: 100%;
//   }
// }
.carousel {
  &--click-to-next {
    cursor: pointer;
  }
  &[data-mouse=left] {
    cursor: w-resize
  }
  &[data-mouse=right] {
    cursor: e-resize
  }
}


/* schedule
---------------------------------------------- */
.schedule {
  &__title {
    margin: var(--small-vertical-margin) 0;
    font-size: var(--large-font-size);
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    @include for-above-small-tablet {
      margin: var(--xx-small-vertical-margin) 0;
    }
  }
}
.event {
  margin-bottom: var(--lr-page-padding);
  background-color: var(--black);
  color: white;
  &__container {
    padding: var(--medium-vertical-margin) 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__venue {
    font-family: var(--serif-font);
    font-size: var(--small-font-size);
    margin-bottom: var(--medium-vertical-margin);
    text-align: center;
  }
  &__image-wrapper {
    margin-bottom: -.5rem;
  }
  &__image {
    height: 75px;
    width: auto;
    display: block;
  }
  &__title {
    margin-bottom: var(--small-vertical-margin);
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    font-size: var(--xx-large-font-size);
    line-height: 96.29%;
  }
  &__date {
    font-family: var(--serif-font);
    font-size: var(--small-font-size);
    text-align: center;
    margin-bottom: var(--medium-vertical-margin);
  }
  &__cal-button {
    text-transform: uppercase;
    margin-bottom: var(--x-small-vertical-margin);
    .underlined {
      text-decoration-color: var(--white);
    }
  }
  &__cal-content {
    margin-bottom: var(--small-vertical-margin);
  }
  &__more-info-accordion {
    width: 100%;
  }
  &__more-info-button {
    margin-top: var(--small-vertical-margin);
    .underlined {
      text-decoration-color: var(--white);
    }
  }
  &__more-info-button-text {
    text-transform: uppercase;
  }
  &__more-info-content {
    width: 100%;
    margin-top: var(--x-small-vertical-margin);
    padding: 0 var(--lr-page-padding);
    text-align: center;
  }
}
.past-events {
  &__accordion-button {
    background: var(--black)!important;
  }
  &__accordion-button-text {
    font-size: var(--large-font-size);
    font-weight: 600;
    text-transform: uppercase;
    color: var(--white);
    padding: var(--x-small-vertical-margin);
  }
}
/* Accordion
---------------------------------------------- */
.accordion {
  $self: &;
  &:not(:last-of-type) {
    margin-bottom: .1em;
  }
  &__button {
    position: relative;
    display: block;
    width: 100%;
    border: none;
    background: none;
    outline: none;
    &:hover, &:focus {
      cursor: pointer;
    }
    // &[aria-expanded='true'] {
    //   + #{ $self }__content {
    //     opacity: 1;
    //     max-height: 2000em;
    //     transition: all 400ms linear;
    //     will-change: opacity, max-height;
    //   }
    // }
  }
  .accordion__content {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 400ms linear, max-height 400ms linear;
    will-change: opacity, max-height;
  }
  &--open > &__content {
    opacity: 1;
    max-height: 2000em;
    transition: all 400ms linear;
    will-change: opacity, max-height;
  }
  .open-text {
    display: block;
  }
  .close-text {
    display: none;
  }
  [aria-expanded=false] {
    .open-text {
      display: block;
    }
    .close-text {
      display: none;
    }
  }
  [aria-expanded=true] {
    .open-text {
      display: none;
    }
    .close-text {
      display: block;
    }
  }
}

/* about
---------------------------------------------- */
.about {
  margin-top: var(--xx-large-vertical-margin);
  font-family: var(--serif-font);
  font-size: var(--medium-large-font-size);
  line-height: 104.99%;
  &__title {
    margin-bottom: var(--xx-large-vertical-margin);
  }
  p {
    text-align: justify;
    margin-bottom: 1.2rem;
  }
  &__read-more-button {
    font-family: var(--sans-serif-font);
    text-transform: uppercase;
    margin-top: var(--medium-vertical-margin);
    font-size: 1rem;
  }
}

/* artists
---------------------------------------------- */
.artists {
  margin: var(--xx-large-vertical-margin) 0;
}
.artist {
  &__accordion-button {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
  }
  &__name {
    font-weight: 600;
    font-size: var(--medium-font-size);
    text-transform: uppercase;
    text-align: left;
  }
  &__accordion-content {
    margin-bottom: var(--x-small-vertical-margin);
  }
  &__info-container {
    margin-top: var(--xx-small-vertical-margin);
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }
  .event {
    margin-bottom: initial;
    background-color: initial;
    color: var(--black);
    border-bottom: .5px solid var(--grey);
  }
}

/* about-org
---------------------------------------------- */
.about-org {
  margin-bottom: var(--x-large-vertical-margin);
  font-family: var(--serif-font);
  font-size: var(--medium-large-font-size);
  line-height: 104.99%;
  p {
    text-align: justify;
    margin-bottom: 1.2rem;
  }
}
.logo {
  width: 100%;
  height: auto;
}
.hawapi-text {
  margin-top: var(--x-small-vertical-margin);
}
.hawapi-links {
  margin: var(--medium-vertical-margin) 0;
  font-family: var(--sans-serif-font);
  font-size: 1rem;
  text-transform: uppercase;
  a {
    display: block;
    padding: 2px 0;
  }
}
#mc_embed_signup{background:#fff; width: 100%;}
/* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
  We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
#mc_embed_signup_scroll {
  display: flex;
  gap: 1rem;
}
#mc_embed_signup form {
  margin: var(--medium-vertical-margin) 0;
}
#mc_embed_signup .button {
  background-color: var(--black);
  border-radius: 0px;
  font-size: 1rem;
  text-transform: uppercase;
  font-family: var(--sans-serif-font);
  padding: 0 22px;
  margin: 0;
  height: 35px;
  line-height: initial;
  -webkit-appearance: none;
  border-radius: 0;
}
#mc_embed_signup input {
  border: none;
  border-bottom: 1px solid var(--black);
  border-radius: 0px;
  font-size: var(--medium-large-font-size);
}
#mc_embed_signup .mc-field-group input {
  padding: 0px 0;
  height: 35px;
  text-indent: 0%;
  &::placeholder {
    color: var(--black)
  }
}
#mc_embed_signup div#mce-responses {
  padding: 0;
  width: 100%;
  margin: 0;
}
#mc_embed_signup div.response {
  font-weight: 400;
  float: initial;
}
#mc_embed_signup #mce-success-response {
  color: var(--black);
  font-size: 1rem;
}
/* credits
---------------------------------------------- */
.credits {
  text-align: center;
  margin: var(--small-vertical-margin) 0 var(--medium-vertical-margin) 0;
  font-family: var(--serif-font);
  font-size: var(--medium-large-font-size);
  &__text {
    margin-top: var(--small-vertical-margin);
    text-align: center!important;
  }
}