/* Setting Media queries
---------------------------------------------- */
@mixin below-small-phone {
  @media (max-width: 375px) { @content; }
}
@mixin for-above-phone {
  @media (min-width: 600px) { @content; }
}
@mixin for-above-small-tablet {
  @media (min-width: 850px) { @content; }
}
// @mixin for-large-tablet-portrait-below {
//   @media (max-width: 1024px) { @content; }
// }
// @mixin for-tablet-landscape-below {
//   @media (max-width: 1200px) { @content; }
// }
// @mixin for-desktop-below {
//   @media (max-width: 1800px) { @content; }
// }
// @mixin for-large-desktop-up {
//   @media (min-width: 1801px) { @content; }
// }